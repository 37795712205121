//#region - Imports
// Libraries
import {format, add, sub} from "date-fns";
import da from "date-fns/locale/da";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

// Styling
import "../../Styles/Components/RentPopUps/EventConfirmCard.scss";

//#endregion

const EventConfirmCard = (props) => {
  const {pSpot, img, eventStartTime, eventEndTime, eventName, eventPrice} = props;

  const handleEventDateTime = (start, end) => {
    let startTime = "";
    let endTime = "";

    if (start != null && start !== "") {
      const subtractionTime = sub(new Date(start), {hours: 1});
      startTime = format(subtractionTime, "dd. MMMM Y - HH:mm", {
        locale: da,
      });
    }

    if (end != null && end !== "") {
      const addTime = add(new Date(end), {minutes: 30});
      endTime = format(addTime, "dd. MMMM Y - HH:mm", {
        locale: da,
      });
    }

    return startTime + endTime;
  };

  const hoverDescription = (props) =>
    pSpot.description.length >= 50 ? (
      <Tooltip className="tooltip-inner" {...props}>
        {pSpot.description}
      </Tooltip>
    ) : (
      <Tooltip></Tooltip>
    );

  const pSpotCard = (
    <div className="pSpotCardContainer" id={pSpot.parkingSpotId}>
      <div className="item1">
        <img src={img} alt="" />
      </div>
      <div className="item2">
        <div className="item2-1">
          <p className="address paragraphHeading">
            {pSpot.address.streetName} {pSpot.address.streetNumber} -{" "}
            {pSpot.address.zipCodeDistrict.city},{" "}
            {pSpot.address.zipCodeDistrict.zipcode}
          </p>
          <p className="price paragraph">
            <span className="bold">Pris: </span>
            {eventPrice},00 DKK
          </p>
            <p className="description paragraph">
              <span className="bold">Beskrivelse: </span>
              {pSpot.description}
            </p>
        </div>

        <div className="item2-2">
          <p className="start paragraph">
            <span className="bold">Start: </span>
            {handleEventDateTime(eventStartTime, null)}
          </p>
          <p className="end paragraph">
            <span className="bold">Slut: </span>
            {handleEventDateTime(null, eventEndTime)}
          </p>
        </div>
      </div>
    </div>
  );

  return <div className="eventConfirmCard">{pSpotCard}</div>;
};

export default EventConfirmCard;
