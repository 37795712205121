export const venue19 = [
    {
        "parkingSpotId": 35,
        "active": true,
        "description": "Til venstre for huset, på pladsen til venstre. Hold til venstre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
        "spotsize": 1,
        "photo": "Parkingspot 70eeb139-09ca-46fb-bbc4-fc24563bd1a5.png",
        "price": 50,
        "eventOutput": {
            "eventId": 6,
            "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
            "name": "Sydbank Pokalfinale",
            "eventCategory": 0,
            "price": 50,
            "startTime": "2021-05-13T15:00:00",
            "endTime": "2021-05-13T17:00:00",
            "venue": {
                "venueId": 19,
                "address": "Stadion Allé 70",
                "description": "",
                "name": "Ceres Park & Arena",
                "lattitude": null,
                "longitude": null,
                "zipCodeDistrict": {
                    "zipcode": 8000,
                    "city": "Aarhus C"
                },
                "area": "Aarhus"
            },
            "numberOfSpots": 8
        },
        "address": {
            "addressId": 15,
            "streetName": "Jyllands alle",
            "streetNumber": "44",
            "lat": null,
            "long": null,
            "active": true,
            "photo": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "zone": null,
            "ownerId": null,
            "area": null
        },
        "addressId": null,
        "rentPeriodId": 1,
        "isRentedOut": false,
        "rentPeriodStart": "2021-05-13T15:00:00",
        "rentPeriodEnd": "2021-05-13T17:00:00"
    },
    {
        "parkingSpotId": 36,
        "active": true,
        "description": "Til venstre for huset, på pladsen i midten. Hold i midten, da der skal være plads til en bil på hver side. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
        "spotsize": 1,
        "photo": "Parkingspot 837224db-b26d-42cc-95c2-871f57aaa20e.png",
        "price": 50,
        "eventOutput": {
            "eventId": 6,
            "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
            "name": "Sydbank Pokalfinale",
            "eventCategory": 0,
            "price": 50,
            "startTime": "2021-05-13T15:00:00",
            "endTime": "2021-05-13T17:00:00",
            "venue": {
                "venueId": 19,
                "address": "Stadion Allé 70",
                "description": "",
                "name": "Ceres Park & Arena",
                "lattitude": null,
                "longitude": null,
                "zipCodeDistrict": {
                    "zipcode": 8000,
                    "city": "Aarhus C"
                },
                "area": "Aarhus"
            },
            "numberOfSpots": 8
        },
        "address": {
            "addressId": 15,
            "streetName": "Jyllands alle",
            "streetNumber": "44",
            "lat": null,
            "long": null,
            "active": true,
            "photo": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "zone": null,
            "ownerId": null,
            "area": null
        },
        "addressId": null,
        "rentPeriodId": 1,
        "isRentedOut": false,
        "rentPeriodStart": "2021-05-13T15:00:00",
        "rentPeriodEnd": "2021-05-13T17:00:00"
    },
    {
        "parkingSpotId": 37,
        "active": true,
        "description": "Til venstre for huset, på pladsen til højre, tættest på huset. Hold til højre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
        "spotsize": 1,
        "photo": "Parkingspot 54d5572a-cba2-4fbf-946b-fb29c69512f6.png",
        "price": 50,
        "eventOutput": {
            "eventId": 6,
            "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
            "name": "Sydbank Pokalfinale",
            "eventCategory": 0,
            "price": 50,
            "startTime": "2021-05-13T15:00:00",
            "endTime": "2021-05-13T17:00:00",
            "venue": {
                "venueId": 19,
                "address": "Stadion Allé 70",
                "description": "",
                "name": "Ceres Park & Arena",
                "lattitude": null,
                "longitude": null,
                "zipCodeDistrict": {
                    "zipcode": 8000,
                    "city": "Aarhus C"
                },
                "area": "Aarhus"
            },
            "numberOfSpots": 8
        },
        "address": {
            "addressId": 15,
            "streetName": "Jyllands alle",
            "streetNumber": "44",
            "lat": null,
            "long": null,
            "active": true,
            "photo": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "zone": null,
            "ownerId": null,
            "area": null
        },
        "addressId": null,
        "rentPeriodId": 1,
        "isRentedOut": false,
        "rentPeriodStart": "2021-05-13T15:00:00",
        "rentPeriodEnd": "2021-05-13T17:00:00"
    },
    {
        "parkingSpotId": 38,
        "active": true,
        "description": "Lille parkering foran garagen. Husk at holde til højre, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
        "spotsize": 0,
        "photo": "Parkingspot bebc1610-04cb-4ebd-a726-5a7e3b19d3d4.png",
        "price": 50,
        "eventOutput": {
            "eventId": 6,
            "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
            "name": "Sydbank Pokalfinale",
            "eventCategory": 0,
            "price": 50,
            "startTime": "2021-05-13T15:00:00",
            "endTime": "2021-05-13T17:00:00",
            "venue": {
                "venueId": 19,
                "address": "Stadion Allé 70",
                "description": "",
                "name": "Ceres Park & Arena",
                "lattitude": null,
                "longitude": null,
                "zipCodeDistrict": {
                    "zipcode": 8000,
                    "city": "Aarhus C"
                },
                "area": "Aarhus"
            },
            "numberOfSpots": 8
        },
        "address": {
            "addressId": 15,
            "streetName": "Jyllands alle",
            "streetNumber": "44",
            "lat": null,
            "long": null,
            "active": true,
            "photo": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "zone": null,
            "ownerId": null,
            "area": null
        },
        "addressId": null,
        "rentPeriodId": 1,
        "isRentedOut": false,
        "rentPeriodStart": "2021-05-13T15:00:00",
        "rentPeriodEnd": "2021-05-13T17:00:00"
    },
    {
        "parkingSpotId": 39,
        "active": true,
        "description": "Ved siden af garagen. Husk at holde op langs garagen og køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
        "spotsize": 1,
        "photo": "Parkingspot 765014a9-d9f4-4ec2-9ae0-7cb0f8cc2f90.png",
        "price": 50,
        "eventOutput": {
            "eventId": 6,
            "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
            "name": "Sydbank Pokalfinale",
            "eventCategory": 0,
            "price": 50,
            "startTime": "2021-05-13T15:00:00",
            "endTime": "2021-05-13T17:00:00",
            "venue": {
                "venueId": 19,
                "address": "Stadion Allé 70",
                "description": "",
                "name": "Ceres Park & Arena",
                "lattitude": null,
                "longitude": null,
                "zipCodeDistrict": {
                    "zipcode": 8000,
                    "city": "Aarhus C"
                },
                "area": "Aarhus"
            },
            "numberOfSpots": 8
        },
        "address": {
            "addressId": 15,
            "streetName": "Jyllands alle",
            "streetNumber": "44",
            "lat": null,
            "long": null,
            "active": true,
            "photo": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "zone": null,
            "ownerId": null,
            "area": null
        },
        "addressId": null,
        "rentPeriodId": 1,
        "isRentedOut": false,
        "rentPeriodStart": "2021-05-13T15:00:00",
        "rentPeriodEnd": "2021-05-13T17:00:00"
    },
    {
        "parkingSpotId": 40,
        "active": true,
        "description": "Lille parkering foran huset i række 2 ved siden af garagen. Husk at holde sådan at der kan være en bil mellem denne plads og garagen. Husk at køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
        "spotsize": 0,
        "photo": "Parkingspot 31376132-45dd-4531-8238-1000c8b75de6.png",
        "price": 50,
        "eventOutput": {
            "eventId": 6,
            "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
            "name": "Sydbank Pokalfinale",
            "eventCategory": 0,
            "price": 50,
            "startTime": "2021-05-13T15:00:00",
            "endTime": "2021-05-13T17:00:00",
            "venue": {
                "venueId": 19,
                "address": "Stadion Allé 70",
                "description": "",
                "name": "Ceres Park & Arena",
                "lattitude": null,
                "longitude": null,
                "zipCodeDistrict": {
                    "zipcode": 8000,
                    "city": "Aarhus C"
                },
                "area": "Aarhus"
            },
            "numberOfSpots": 8
        },
        "address": {
            "addressId": 15,
            "streetName": "Jyllands alle",
            "streetNumber": "44",
            "lat": null,
            "long": null,
            "active": true,
            "photo": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "zone": null,
            "ownerId": null,
            "area": null
        },
        "addressId": null,
        "rentPeriodId": 1,
        "isRentedOut": false,
        "rentPeriodStart": "2021-05-13T15:00:00",
        "rentPeriodEnd": "2021-05-13T17:00:00"
    },
];

export const venue20 = [
  {
    parkingSpotId: 35,
    active: true,
    description:
      "Til venstre for huset, på pladsen til venstre. Hold til venstre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 70eeb139-09ca-46fb-bbc4-fc24563bd1a5.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 36,
    active: true,
    description:
      "Til venstre for huset, på pladsen i midten. Hold i midten, da der skal være plads til en bil på hver side. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 837224db-b26d-42cc-95c2-871f57aaa20e.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 37,
    active: true,
    description:
      "Til venstre for huset, på pladsen til højre, tættest på huset. Hold til højre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 54d5572a-cba2-4fbf-946b-fb29c69512f6.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 38,
    active: true,
    description:
      "Lille parkering foran garagen. Husk at holde til højre, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 0,
    photo: "Parkingspot bebc1610-04cb-4ebd-a726-5a7e3b19d3d4.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 39,
    active: true,
    description:
      "Ved siden af garagen. Husk at holde op langs garagen og køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 765014a9-d9f4-4ec2-9ae0-7cb0f8cc2f90.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 40,
    active: true,
    description:
      "Lille parkering foran huset i række 2 ved siden af garagen. Husk at holde sådan at der kan være en bil mellem denne plads og garagen. Husk at køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 0,
    photo: "Parkingspot 31376132-45dd-4531-8238-1000c8b75de6.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
];

export const venue21 = [
  {
    parkingSpotId: 35,
    active: true,
    description:
      "Til venstre for huset, på pladsen til venstre. Hold til venstre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 70eeb139-09ca-46fb-bbc4-fc24563bd1a5.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 36,
    active: true,
    description:
      "Til venstre for huset, på pladsen i midten. Hold i midten, da der skal være plads til en bil på hver side. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 837224db-b26d-42cc-95c2-871f57aaa20e.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 37,
    active: true,
    description:
      "Til venstre for huset, på pladsen til højre, tættest på huset. Hold til højre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 54d5572a-cba2-4fbf-946b-fb29c69512f6.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 38,
    active: true,
    description:
      "Lille parkering foran garagen. Husk at holde til højre, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 0,
    photo: "Parkingspot bebc1610-04cb-4ebd-a726-5a7e3b19d3d4.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 39,
    active: true,
    description:
      "Ved siden af garagen. Husk at holde op langs garagen og køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 765014a9-d9f4-4ec2-9ae0-7cb0f8cc2f90.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 40,
    active: true,
    description:
      "Lille parkering foran huset i række 2 ved siden af garagen. Husk at holde sådan at der kan være en bil mellem denne plads og garagen. Husk at køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 0,
    photo: "Parkingspot 31376132-45dd-4531-8238-1000c8b75de6.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
];

export const venue22 = [
  {
    parkingSpotId: 35,
    active: true,
    description:
      "Til venstre for huset, på pladsen til venstre. Hold til venstre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 70eeb139-09ca-46fb-bbc4-fc24563bd1a5.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 36,
    active: true,
    description:
      "Til venstre for huset, på pladsen i midten. Hold i midten, da der skal være plads til en bil på hver side. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 837224db-b26d-42cc-95c2-871f57aaa20e.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 37,
    active: true,
    description:
      "Til venstre for huset, på pladsen til højre, tættest på huset. Hold til højre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 54d5572a-cba2-4fbf-946b-fb29c69512f6.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 38,
    active: true,
    description:
      "Lille parkering foran garagen. Husk at holde til højre, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 0,
    photo: "Parkingspot bebc1610-04cb-4ebd-a726-5a7e3b19d3d4.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 39,
    active: true,
    description:
      "Ved siden af garagen. Husk at holde op langs garagen og køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 765014a9-d9f4-4ec2-9ae0-7cb0f8cc2f90.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    parkingSpotId: 40,
    active: true,
    description:
      "Lille parkering foran huset i række 2 ved siden af garagen. Husk at holde sådan at der kan være en bil mellem denne plads og garagen. Husk at køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 0,
    photo: "Parkingspot 31376132-45dd-4531-8238-1000c8b75de6.png",
    price: 50,
    eventOutput: {
      eventId: 26,
      description: "Fed Fredag - Lars Lilholt Band",
      name: "Lars Lilholt Band",
      eventCategory: 1,
      price: 50,
      startTime: "2021-06-04T20:00:00",
      endTime: "2021-06-04T23:00:00",
      venue: {
        venueId: 20,
        address: "Skovbrynet 5",
        description: "",
        name: "Tivoli Friheden",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-06-04T20:00:00",
    rentPeriodEnd: "2021-06-04T23:00:00",
  },
  {
    "parkingSpotId": 35,
    "active": true,
    "description": "Til venstre for huset, på pladsen til venstre. Hold til venstre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 1,
    "photo": "Parkingspot 70eeb139-09ca-46fb-bbc4-fc24563bd1a5.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 36,
    "active": true,
    "description": "Til venstre for huset, på pladsen i midten. Hold i midten, da der skal være plads til en bil på hver side. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 1,
    "photo": "Parkingspot 837224db-b26d-42cc-95c2-871f57aaa20e.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 37,
    "active": true,
    "description": "Til venstre for huset, på pladsen til højre, tættest på huset. Hold til højre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 1,
    "photo": "Parkingspot 54d5572a-cba2-4fbf-946b-fb29c69512f6.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 38,
    "active": true,
    "description": "Lille parkering foran garagen. Husk at holde til højre, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 0,
    "photo": "Parkingspot bebc1610-04cb-4ebd-a726-5a7e3b19d3d4.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 39,
    "active": true,
    "description": "Ved siden af garagen. Husk at holde op langs garagen og køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 1,
    "photo": "Parkingspot 765014a9-d9f4-4ec2-9ae0-7cb0f8cc2f90.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 40,
    "active": true,
    "description": "Lille parkering foran huset i række 2 ved siden af garagen. Husk at holde sådan at der kan være en bil mellem denne plads og garagen. Husk at køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 0,
    "photo": "Parkingspot 31376132-45dd-4531-8238-1000c8b75de6.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},

];

export const venue23 =[

  {
    parkingSpotId: 35,
    active: true,
    description:
      "Til venstre for huset, på pladsen til venstre. Hold til venstre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 70eeb139-09ca-46fb-bbc4-fc24563bd1a5.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 36,
    active: true,
    description:
      "Til venstre for huset, på pladsen i midten. Hold i midten, da der skal være plads til en bil på hver side. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 837224db-b26d-42cc-95c2-871f57aaa20e.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 37,
    active: true,
    description:
      "Til venstre for huset, på pladsen til højre, tættest på huset. Hold til højre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 54d5572a-cba2-4fbf-946b-fb29c69512f6.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 38,
    active: true,
    description:
      "Lille parkering foran garagen. Husk at holde til højre, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 0,
    photo: "Parkingspot bebc1610-04cb-4ebd-a726-5a7e3b19d3d4.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 39,
    active: true,
    description:
      "Ved siden af garagen. Husk at holde op langs garagen og køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 1,
    photo: "Parkingspot 765014a9-d9f4-4ec2-9ae0-7cb0f8cc2f90.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
  {
    parkingSpotId: 40,
    active: true,
    description:
      "Lille parkering foran huset i række 2 ved siden af garagen. Husk at holde sådan at der kan være en bil mellem denne plads og garagen. Husk at køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    spotsize: 0,
    photo: "Parkingspot 31376132-45dd-4531-8238-1000c8b75de6.png",
    price: 50,
    eventOutput: {
      eventId: 13,
      description:
        '"Verdens Største Firmafest" - DHL Stafetten i Aarhus, dag 1 - Tirsdag.',
      name: "DHL Stafet - Dag 1 - Tirsdag",
      eventCategory: 2,
      price: 50,
      startTime: "2021-08-17T18:30:00",
      endTime: "2021-08-17T22:00:00",
      venue: {
        venueId: 21,
        address: "Kongevejen 100",
        description: "",
        name: "Mindeparken",
        lattitude: null,
        longitude: null,
        zipCodeDistrict: {
          zipcode: 8000,
          city: "Aarhus C",
        },
        area: "Aarhus",
      },
      numberOfSpots: 8,
    },
    address: {
      addressId: 15,
      streetName: "Jyllands alle",
      streetNumber: "44",
      lat: null,
      long: null,
      active: true,
      photo: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      zone: null,
      ownerId: null,
      area: null,
    },
    addressId: null,
    rentPeriodId: 1,
    isRentedOut: false,
    rentPeriodStart: "2021-08-17T18:30:00",
    rentPeriodEnd: "2021-08-17T22:00:00",
  },
];
export const venue24 =[
  
];
export const venue25 =[
  
];
export const venue26 =[
  {
    "parkingSpotId": 35,
    "active": true,
    "description": "Til venstre for huset, på pladsen til venstre. Hold til venstre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 1,
    "photo": "Parkingspot 70eeb139-09ca-46fb-bbc4-fc24563bd1a5.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 36,
    "active": true,
    "description": "Til venstre for huset, på pladsen i midten. Hold i midten, da der skal være plads til en bil på hver side. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 1,
    "photo": "Parkingspot 837224db-b26d-42cc-95c2-871f57aaa20e.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 37,
    "active": true,
    "description": "Til venstre for huset, på pladsen til højre, tættest på huset. Hold til højre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 1,
    "photo": "Parkingspot 54d5572a-cba2-4fbf-946b-fb29c69512f6.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 38,
    "active": true,
    "description": "Lille parkering foran garagen. Husk at holde til højre, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 0,
    "photo": "Parkingspot bebc1610-04cb-4ebd-a726-5a7e3b19d3d4.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 39,
    "active": true,
    "description": "Ved siden af garagen. Husk at holde op langs garagen og køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 1,
    "photo": "Parkingspot 765014a9-d9f4-4ec2-9ae0-7cb0f8cc2f90.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
    "parkingSpotId": 40,
    "active": true,
    "description": "Lille parkering foran huset i række 2 ved siden af garagen. Husk at holde sådan at der kan være en bil mellem denne plads og garagen. Husk at køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
    "spotsize": 0,
    "photo": "Parkingspot 31376132-45dd-4531-8238-1000c8b75de6.png",
    "price": 50,
    "eventOutput": {
        "eventId": 6,
        "description": "Sydbank Pokalfinale mellem Randers FC og Sønderjyske",
        "name": "Sydbank Pokalfinale",
        "eventCategory": 0,
        "price": 50,
        "startTime": "2021-05-13T15:00:00",
        "endTime": "2021-05-13T17:00:00",
        "venue": {
            "venueId": 19,
            "address": "Stadion Allé 70",
            "description": "",
            "name": "Ceres Park & Arena",
            "lattitude": null,
            "longitude": null,
            "zipCodeDistrict": {
                "zipcode": 8000,
                "city": "Aarhus C"
            },
            "area": "Aarhus"
        },
        "numberOfSpots": 8
    },
    "address": {
        "addressId": 15,
        "streetName": "Jyllands alle",
        "streetNumber": "44",
        "lat": null,
        "long": null,
        "active": true,
        "photo": null,
        "zipCodeDistrict": {
            "zipcode": 8000,
            "city": "Aarhus C"
        },
        "zone": null,
        "ownerId": null,
        "area": null
    },
    "addressId": null,
    "rentPeriodId": 1,
    "isRentedOut": false,
    "rentPeriodStart": "2021-05-13T15:00:00",
    "rentPeriodEnd": "2021-05-13T17:00:00"
},
{
  parkingSpotId: 35,
  active: true,
  description:
    "Til venstre for huset, på pladsen til venstre. Hold til venstre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
  spotsize: 1,
  photo: "Parkingspot 70eeb139-09ca-46fb-bbc4-fc24563bd1a5.png",
  price: 50,
  eventOutput: {
    eventId: 26,
    description: "Fed Fredag - Lars Lilholt Band",
    name: "Lars Lilholt Band",
    eventCategory: 1,
    price: 50,
    startTime: "2021-06-04T20:00:00",
    endTime: "2021-06-04T23:00:00",
    venue: {
      venueId: 20,
      address: "Skovbrynet 5",
      description: "",
      name: "Tivoli Friheden",
      lattitude: null,
      longitude: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      area: "Aarhus",
    },
    numberOfSpots: 8,
  },
  address: {
    addressId: 15,
    streetName: "Jyllands alle",
    streetNumber: "44",
    lat: null,
    long: null,
    active: true,
    photo: null,
    zipCodeDistrict: {
      zipcode: 8000,
      city: "Aarhus C",
    },
    zone: null,
    ownerId: null,
    area: null,
  },
  addressId: null,
  rentPeriodId: 1,
  isRentedOut: false,
  rentPeriodStart: "2021-06-04T20:00:00",
  rentPeriodEnd: "2021-06-04T23:00:00",
},
{
  parkingSpotId: 36,
  active: true,
  description:
    "Til venstre for huset, på pladsen i midten. Hold i midten, da der skal være plads til en bil på hver side. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
  spotsize: 1,
  photo: "Parkingspot 837224db-b26d-42cc-95c2-871f57aaa20e.png",
  price: 50,
  eventOutput: {
    eventId: 26,
    description: "Fed Fredag - Lars Lilholt Band",
    name: "Lars Lilholt Band",
    eventCategory: 1,
    price: 50,
    startTime: "2021-06-04T20:00:00",
    endTime: "2021-06-04T23:00:00",
    venue: {
      venueId: 20,
      address: "Skovbrynet 5",
      description: "",
      name: "Tivoli Friheden",
      lattitude: null,
      longitude: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      area: "Aarhus",
    },
    numberOfSpots: 8,
  },
  address: {
    addressId: 15,
    streetName: "Jyllands alle",
    streetNumber: "44",
    lat: null,
    long: null,
    active: true,
    photo: null,
    zipCodeDistrict: {
      zipcode: 8000,
      city: "Aarhus C",
    },
    zone: null,
    ownerId: null,
    area: null,
  },
  addressId: null,
  rentPeriodId: 1,
  isRentedOut: false,
  rentPeriodStart: "2021-06-04T20:00:00",
  rentPeriodEnd: "2021-06-04T23:00:00",
},
{
  parkingSpotId: 37,
  active: true,
  description:
    "Til venstre for huset, på pladsen til højre, tættest på huset. Hold til højre, da der skal være plads til 2 bil ved siden af. Der skal parkeres med front eller bagende op mod hækken. Det er en fodel at bakke ind, så det er lettere at komme ud igen.",
  spotsize: 1,
  photo: "Parkingspot 54d5572a-cba2-4fbf-946b-fb29c69512f6.png",
  price: 50,
  eventOutput: {
    eventId: 26,
    description: "Fed Fredag - Lars Lilholt Band",
    name: "Lars Lilholt Band",
    eventCategory: 1,
    price: 50,
    startTime: "2021-06-04T20:00:00",
    endTime: "2021-06-04T23:00:00",
    venue: {
      venueId: 20,
      address: "Skovbrynet 5",
      description: "",
      name: "Tivoli Friheden",
      lattitude: null,
      longitude: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      area: "Aarhus",
    },
    numberOfSpots: 8,
  },
  address: {
    addressId: 15,
    streetName: "Jyllands alle",
    streetNumber: "44",
    lat: null,
    long: null,
    active: true,
    photo: null,
    zipCodeDistrict: {
      zipcode: 8000,
      city: "Aarhus C",
    },
    zone: null,
    ownerId: null,
    area: null,
  },
  addressId: null,
  rentPeriodId: 1,
  isRentedOut: false,
  rentPeriodStart: "2021-06-04T20:00:00",
  rentPeriodEnd: "2021-06-04T23:00:00",
},
{
  parkingSpotId: 38,
  active: true,
  description:
    "Lille parkering foran garagen. Husk at holde til højre, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
  spotsize: 0,
  photo: "Parkingspot bebc1610-04cb-4ebd-a726-5a7e3b19d3d4.png",
  price: 50,
  eventOutput: {
    eventId: 26,
    description: "Fed Fredag - Lars Lilholt Band",
    name: "Lars Lilholt Band",
    eventCategory: 1,
    price: 50,
    startTime: "2021-06-04T20:00:00",
    endTime: "2021-06-04T23:00:00",
    venue: {
      venueId: 20,
      address: "Skovbrynet 5",
      description: "",
      name: "Tivoli Friheden",
      lattitude: null,
      longitude: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      area: "Aarhus",
    },
    numberOfSpots: 8,
  },
  address: {
    addressId: 15,
    streetName: "Jyllands alle",
    streetNumber: "44",
    lat: null,
    long: null,
    active: true,
    photo: null,
    zipCodeDistrict: {
      zipcode: 8000,
      city: "Aarhus C",
    },
    zone: null,
    ownerId: null,
    area: null,
  },
  addressId: null,
  rentPeriodId: 1,
  isRentedOut: false,
  rentPeriodStart: "2021-06-04T20:00:00",
  rentPeriodEnd: "2021-06-04T23:00:00",
},
{
  parkingSpotId: 39,
  active: true,
  description:
    "Ved siden af garagen. Husk at holde op langs garagen og køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
  spotsize: 1,
  photo: "Parkingspot 765014a9-d9f4-4ec2-9ae0-7cb0f8cc2f90.png",
  price: 50,
  eventOutput: {
    eventId: 26,
    description: "Fed Fredag - Lars Lilholt Band",
    name: "Lars Lilholt Band",
    eventCategory: 1,
    price: 50,
    startTime: "2021-06-04T20:00:00",
    endTime: "2021-06-04T23:00:00",
    venue: {
      venueId: 20,
      address: "Skovbrynet 5",
      description: "",
      name: "Tivoli Friheden",
      lattitude: null,
      longitude: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      area: "Aarhus",
    },
    numberOfSpots: 8,
  },
  address: {
    addressId: 15,
    streetName: "Jyllands alle",
    streetNumber: "44",
    lat: null,
    long: null,
    active: true,
    photo: null,
    zipCodeDistrict: {
      zipcode: 8000,
      city: "Aarhus C",
    },
    zone: null,
    ownerId: null,
    area: null,
  },
  addressId: null,
  rentPeriodId: 1,
  isRentedOut: false,
  rentPeriodStart: "2021-06-04T20:00:00",
  rentPeriodEnd: "2021-06-04T23:00:00",
},
{
  parkingSpotId: 40,
  active: true,
  description:
    "Lille parkering foran huset i række 2 ved siden af garagen. Husk at holde sådan at der kan være en bil mellem denne plads og garagen. Husk at køre helt op til huset, sådan at andre biler kan køre ind og ud af indkørslen. Det kan være en fodel at bakke ind, så det er lettere at komme ud igen.",
  spotsize: 0,
  photo: "Parkingspot 31376132-45dd-4531-8238-1000c8b75de6.png",
  price: 50,
  eventOutput: {
    eventId: 26,
    description: "Fed Fredag - Lars Lilholt Band",
    name: "Lars Lilholt Band",
    eventCategory: 1,
    price: 50,
    startTime: "2021-06-04T20:00:00",
    endTime: "2021-06-04T23:00:00",
    venue: {
      venueId: 20,
      address: "Skovbrynet 5",
      description: "",
      name: "Tivoli Friheden",
      lattitude: null,
      longitude: null,
      zipCodeDistrict: {
        zipcode: 8000,
        city: "Aarhus C",
      },
      area: "Aarhus",
    },
    numberOfSpots: 8,
  },
  address: {
    addressId: 15,
    streetName: "Jyllands alle",
    streetNumber: "44",
    lat: null,
    long: null,
    active: true,
    photo: null,
    zipCodeDistrict: {
      zipcode: 8000,
      city: "Aarhus C",
    },
    zone: null,
    ownerId: null,
    area: null,
  },
  addressId: null,
  rentPeriodId: 1,
  isRentedOut: false,
  rentPeriodStart: "2021-06-04T20:00:00",
  rentPeriodEnd: "2021-06-04T23:00:00",
},
  
];
export const venue27 =[
  
];

export const venues = {
    '19': venue19, '20': venue20, '21': venue21, '22' : venue22, '23' : venue23, '24' :venue24, '25' :venue25, '26' :venue26, '27' :venue27
}