//#region - Imports
// Libraries
import { useState } from "react";
import { Col, Form, Button, InputGroup } from "react-bootstrap";

// Styling
import "../../Styles/Components/RentPopUps/EventConfirmForm.scss";

// Component
import { validateInfo } from "../Validation/InputFormValidation";
import { client } from '../Api/Client'

//#endregion

const EventConfirmForm = (props) => {
  const { spot, setConfirmed, eventStartTime, eventEndTime, eventName, eventLocation, eventPrice,eventId} = props;
  console.log(spot)

  //#region - States
  const initialUserData = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    extendedMsg: "",
    msg: "",
    terms: false,
  };
  const [formData, setFormData] = useState(initialUserData);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  //#endregion

  //#region - Handlers
  const handleChange = (e) => {
    const updatedData = { ...formData, [e.target.id]: e.target.value };
    setFormData(updatedData);
  };

  const clearCheckbox = () => {
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("This is",eventName)

    setLoading(true);
    if (validate()) {
      try {
        client
          .post("Contact/BuyParkingSpotEventType", {
            body: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              phoneNumber: formData.phoneNumber,
              message: formData.msg,
              extendParkingMsg: formData.extendedMsg,
              parkingSpotId: spot.parkingSpotId,
              rentPeriodStart: eventStartTime,
              rentPeriodEnd: eventEndTime,
              eventId: eventId,
              eventName: eventName,
              eventStartTime: eventStartTime,
              eventEndTime: eventEndTime,
              eventPrice: eventPrice,
              location: eventLocation
            }
          })
          .then(
            (res) => {
              console.log("Succesfully Requested", res);
              if (res.status === 200) {
                setLoading(false);
                setFormData(initialUserData);
                clearCheckbox();
                setConfirmed(true);
              }
            },
            (error) => console.log(error.response.data)
          );
      } catch (error) {
        // setIsValid(false);
        setLoading(false);
        console.log(error);
      }
    } else {
      setLoading(false);
    }
  };

  //#endregion

  //#region - Validation
  const validate = () => {
    let isValid = true;
    const validationObject = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      extendParkingMsg: formData.extendedMsg ? formData.extendedMsg : null,
      msg: formData.msg ? formData.msg : null,
      terms: formData.terms,
    };
    const event = validateInfo(validationObject);
    console.log("validation", event);
    if (Object.keys(event).length !== 0) {
      isValid = false;
    }
    setErrors(event);
    return isValid;
  };

  //#endregion

  //#region - Return
  return (
    <div className="eventConfirmForm">
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Row>
          <Form.Group as={Col} controlId="firstName">
            <Form.Label className="formlabel">Fornavn</Form.Label>
            <Form.Control
              type="text"
              placeholder="Indtast dit fornavn"
              required
              // name="firstName"
              onChange={handleChange}
              value={formData.firstName}
              isInvalid={!!errors.firstName}
            />
            {errors.firstName ? (
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="lastName">
            <Form.Label className="formlabel">Efternavn</Form.Label>
            <Form.Control
              type="text"
              placeholder="Indtast dit efternavn"
              required
              onChange={handleChange}
              value={formData.lastName}
              isInvalid={!!errors.lastName}
            />
            {errors.lastName ? (
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="email">
            <Form.Label className="formlabel">Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Indtast din email"
              required
              onChange={handleChange}
              value={formData.email}
              isInvalid={!!errors.email}
            />
            {errors.email ? (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="phoneNumber">
            <Form.Label className="formlabel">Telefonnummer</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>+45</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="tel"
                placeholder="Indtast dit telefonnummer"
                aria-describedby="inputGroupPrepend"
                required
                onChange={handleChange}
                value={formData.phoneNumber}
                isInvalid={!!errors.phoneNumber}
                className={errors.phoneNumber ? "inputgroup-right-radius" : ""}
              />
              {errors.phoneNumber ? (
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="extendedMsg">
          <Form.Label className="formlabel">
            Hvis længere parkering ønskes så skriv her{" "}
            <span className="formlabel-optional">(valgfri)</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            onChange={handleChange}
            value={formData.box1}
          />
        </Form.Group>
        <Form.Group controlId="msg">
          <Form.Label className="formlabel">
            Besked <span className="formlabel-optional">(valgfri)</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            onChange={handleChange}
            value={formData.box2}
          />
        </Form.Group>
        <p className="betalingInfo paragraph">
          <span className="bold">Betaling: </span>ParkShare kontakter dig
          snarest muligt for mere information om betaling, og en endelig
          bekræftelse på parkeringspladsen.
        </p>
        <Form.Group controlId="terms">
          <Form.Check
            type="checkbox"
            onChange={handleChange}
            label={
              <span>
                Accepter{" "}
                <a target="_blank" href="/Terms">
                  Servicevilkår
                </a>
                *
              </span>
            }
            isInvalid={!!errors.terms}
            feedback={errors.terms}
          />
        </Form.Group>
        <Form.Group>
          <div className="regular-button">
            {loading ? (
              <Button disabled id="btn-submit" type="submit">
                Sender...
              </Button>
            ) : (
              <Button id="btn-submit" type="submit">
                Send
              </Button>
            )}
          </div>
        </Form.Group>
      </Form>
    </div>
  );
  //#endregion
};

export default EventConfirmForm;
