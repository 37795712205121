//#region - Imports
// Libraries
import {useState, useEffect} from "react";
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import {HashLink} from "react-router-hash-link";

// Styling
import "../../Styles/Components/ParkingList/FindParkingList.scss";

// Assets
import car from "../../Assets/Event/blueCar.png";
import house from "../../Assets/Event/houseIcon.png";

// Components
import ParkingList from "./ParkingList";
import {sortingParkingList} from "../SortingList";
import {client} from "../Api/Client";
import {parkingSpots} from "../FakeData/parkingSpots";

//#endregion

const FindParkeringList = () => {
  const [state, setState] = useState({
    cityOption: "Alle",
    parkingListArray: [],
    image: {
      imgArray: [],
      defaultImg:
        "https://placeholder.com/wp-content/uploads/2019/06/stock-images.webp",
    },
    filter: {
      isFiltered: false,
      filteredParkingList: [],
    },
    loading: false,
    isLoaded: false,
  });

  useEffect(() => {
    // const sortedArr = sortingParkingList(parkingSpots);

    // setState({
    //   ...state,
    //   parkingListArray: sortedArr,
    // });

    setState({...state, loading: true});
    client
      .get("ParkingSpot/GetAvailableParkingspots", {
        params: {
          periodType: 1,
        },
      })
      .then((res) => {
       // console.log("DATA FROM REQUEST", res.data);

        const sortedArr = sortingParkingList(res.data);

        setState({
          ...state,
          parkingListArray: sortedArr
        });
      });
  }, []);
  
  const getImages = () => {
    let idList = [];
    let imgArrayList = [];
    state.parkingListArray.forEach((pspot) => {
      const pspotID = pspot.parkingSpotId;
      idList.push(pspotID);
    });

    idList.forEach((id) => {
      client
        .get("ParkingSpot/GetPhoto", {
          params: {
            parkingSpotId: id,
          },
          responseType: "blob",
        })
        .then((res) => {
           //console.log("Data from 'GetPhoto' request:  ", res);
          if (res.status === 200) {
            var blob = new Blob([res.data], {
              // type: "application/octet-binary",
              type: "application/octet-stream",
            });
            const newBlob = URL.createObjectURL(blob);
            const imgObject = {id: id, img: newBlob};
            imgArrayList.push(imgObject);

            setState({
              ...state,
              image: {
                ...state.image,
                imgArray: imgArrayList,
              },
              loading: false,
              isLoaded: true,
            });
          } else {
            const imgObject = {id: id, img: state.image.defaultImg};
            imgArrayList.push(imgObject);
            setState({
              ...state,
              image: {
                ...state.image,
                imgArray: imgArrayList,
              },
              loading: false,
              isLoaded: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  useEffect(() => {
    if (state.parkingListArray.length > 1) {
      getImages();
    }
  }, [state.parkingListArray]);

  //#region - Handlers

  const handleCityBtn = (cityName) => {
   // console.log("Cityname:    ", cityName);
    // setState({
    //   ...state,
    //   cityOption: cityName,
    // });

    const plArray = state.parkingListArray;
    //console.log("Consoling the parkingListArray:   ", plArray);
    if (cityName !== "Alle") {
      const parkingListFilter = plArray.filter(
        (e) => e?.address?.area === cityName
      );

     // console.log("Consoling the filter result:   ", parkingListFilter);
      setState({
        ...state,
        cityOption: cityName,
        filter: {
          ...state.filter,
          isFiltered: true,
          filteredParkingList: parkingListFilter,
        },
      });
    } else {
      setState({
        ...state,
        cityOption: cityName,
        filter: {
          ...state.filter,
          isFiltered: true,
          filteredParkingList: plArray,
        },
      });
    }
  };

  const handleTooltip = (props) => {
    const toolTip = (
      <Tooltip id="button-tooltip" {...props}>
        Kommer snart!
      </Tooltip>
    );
    return toolTip;
  };

  //#endregion

  // Class names for city buttons
  let all =
    state.cityOption === "Alle" ? "all leftBtn blueBtn" : "all leftBtn greyBtn";
  let aarhus =
    state.cityOption === "Aarhus"
      ? "aarhus midBtn blueBtn"
      : "aarhus midBtn greyBtn";
  let kbh =
    state.cityOption === "København"
      ? "kbh midBtn blueBtn"
      : "kbh midBtn greyBtn";
  let aalborg =
    state.cityOption === "Aalborg"
      ? "aalborg midBtn blueBtn"
      : "aalborg midBtn greyBtn";
  let odense =
    state.cityOption === "Odense"
      ? "odense midBtn blueBtn"
      : "odense midBtn greyBtn";
  let andet =
    state.cityOption === "Andet"
      ? "other rightBtn blueBtn"
      : "other rightBtn greyBtn";

  const typeParking = (
    <div className="typeParking">
      <p>
        <span className="paragraphHeading">Filter </span>
      </p>
      <button id="btnMonthlyParking" disabled>
        Måned
      </button>
      <div className="typeParkingLineBreak"></div>
      <OverlayTrigger
        placement="top"
        delay={{show: 250, hide: 400}}
        overlay={handleTooltip}
      >
        <div className="btnTimeParkingWrapper">
          <button id="btnTimeParking" disabled>
            Time
          </button>
        </div>
      </OverlayTrigger>
    </div>
  );

  const cityBtn = (
    <div className="cityButtons">
      <div className={all} onClick={() => handleCityBtn("Alle")}>
        Alle
      </div>
      <div className={aarhus} onClick={() => handleCityBtn("Aarhus")}>
        Aarhus
      </div>
      <div className="lineBreak1"></div>
      <div className={kbh} onClick={() => handleCityBtn("København")}>
        København
      </div>
      <div className="lineBreak2"></div>
      <div className={aalborg} onClick={() => handleCityBtn("Aalborg")}>
        Aalborg
      </div>
      <div className="lineBreak3"></div>
      <div className={odense} onClick={() => handleCityBtn("Odense")}>
        Odense
      </div>
      <div className={andet} onClick={() => handleCityBtn("Andet")}>
        Andre byer
      </div>
    </div>
  );

  const noParkering = (
    <div className="noParkeringContainer">
      <div className="text">
        <h3 className="paragraphHeading">Der er for meget trafik</h3>
        <p className="paragraph">
          Der er meget stor efterspørgsel på parkeringspladser i dette område.
          Vi har derfor desværre ikke nogle tilgængelige lige nu, men kom gerne
          tilbage senere eller skriv dig på vores venteliste nedenfor, så
          kontakter vi dig når der kommer en ledig parkering, der matcher dit
          ønske.
        </p>
        <HashLink smooth to="/find-parkering#monthlywaitinglist">
          <Button id="redirectionToWaitingListBtn">Kom på venteliste</Button>
        </HashLink>
      </div>
      <div className="images">
        <img src={car} alt="Blue car" />
        <img src={car} alt="Blue car" />
        <img src={car} alt="Blue car" />
        <img src={house} alt="Pink house" className="house" />
      </div>
    </div>
  );

  let parkingListArrayComponent = state.filter.isFiltered
    ? state.filter.filteredParkingList
    : state.parkingListArray;

  const loading =
    state.loading === true ? (
      <p className="loading sectionHeading">Indlæser...</p>
    ) : (
      noParkering
    );

  const parkingComponent =
    parkingListArrayComponent.length >= 1 ? (
      <ParkingList
        parkingListArray={parkingListArrayComponent}
        imgArray={state.image.imgArray}
        isLoaded={state.isLoaded}
      />
    ) : (
      loading
    );

  return (
    <div className="findParkingList" id="parkinglist">
      <h2 className="sectionHeading">Find parkering nu</h2>
      <div className="findParkeringListComponent">
        {typeParking}
        {cityBtn}
        {parkingComponent}
      </div>
    </div>
  );
};

export default FindParkeringList;
