//#region - Imports
import React, {useState, useEffect} from "react";
import Slider from "react-slick";
import {OverlayTrigger, Tooltip, Button, Card} from "react-bootstrap";
import {HashLink} from "react-router-hash-link";
import {format, parseISO} from "date-fns";
import da from "date-fns/locale/da";

// Styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Styles/Components/ParkingList/EventList.scss";

// Assets
import concertIcon from "../../Assets/Event/concertIcon_black.png";
import football from "../../Assets/Event/footballEvent.png";
import balloon from "../../Assets/Event/balloon1.png";
import car from "../../Assets/Event/blueCar.png";
import house from "../../Assets/Event/houseIcon.png";

// Components
import ParkingList from "./ParkingList";
import {sortingEventList} from "../SortingList";
import {client} from "../Api/Client";
import {venues} from "../FakeData/venues";
import {config} from "./EventListCarouselConfig";
//#endregion

const EventList = () => {
  const [state, setState] = useState({
    eventList: [],
    parkingListArray: [],
    noParkingSpots: false,
    image: {
      imgArray: [],
      defaultImg:
        "https://placeholder.com/wp-content/uploads/2019/06/stock-images.webp",
    },
  });

  const [pressedEvent, setPressedEvent] = useState({
    eventPressed: false,
    eventId: "",
    eventName: "",
    eventLocation:"",
    startTime: "",
    endTime: "",
    eventPrice:""
  });


  useEffect(() => {
    try {
      client.get("Event/GetAllEvents", {}).then((res) => {
       // console.log("DATAAAA FROM REQUEST", res.data);
      const sortedArr = sortingEventList(res.data);
      setState({...state, eventList: sortedArr});
      }); 
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getImages = () => {
    let idList = [];
    let imgArrayList = [];
    state.parkingListArray.forEach((pspot) => {
      const pspotID = pspot.parkingSpotId;
      idList.push(pspotID);
    });

    idList.forEach((id) => {
      client
        .get("ParkingSpot/GetPhoto", {
          params: {
            parkingSpotId: id,
          },
          responseType: "blob",
        })
        .then((res) => {
         // console.log("Data from 'GetPhoto' request:  ", res);
          if (res.status === 200) {
            var blob = new Blob([res.data], {
              // type: "application/octet-binary",
              type: "application/octet-stream",
            });
            const newBlob = URL.createObjectURL(blob);
            const imgObject = {id: id, img: newBlob};
            imgArrayList.push(imgObject);
            setState({
              ...state,
              image: {
                ...state.image,
                imgArray: imgArrayList,
              },
            });
          } else {
            const imgObject = {id: id, img: state.image.defaultImg};
            const imgArrayList = [imgObject, ...state.image.imgArray];
            setState({
              ...state,
              image: {
                ...state.image,
                imgArray: imgArrayList,
              },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  useEffect(() => {
    if (state?.parkingListArray?.length > 1) {
      getImages();
    }
  }, [state.parkingListArray]);

  const parkingSpotsFromAPI=()=>{
    try {
      client.get("/ParkingSpot/GetAvailableParkingspots?periodType=1", {}).then((res) => {
       console.log("Active", res.data);
      }); 
    } catch (error) {
      console.log(error);
    }

  }

  const handleEventClick = (e) => {
    const parkingSpots = 18 < Number(e.currentTarget.dataset.venue_id) &&
      Number(e.currentTarget.dataset.venue_id) < 28
        ? venues[e.currentTarget.dataset.venue_id]
        : [];

    // console.log("Parkingspots  ", parkingSpots);
     console.log("Event onClick dataset:   ", e.currentTarget.dataset);

    setState({
      ...state,
      parkingListArray: parkingSpots,

      /*image: {
        imgArray: imgArray,
      },*/
    });
    setPressedEvent({
      eventPressed: true,
      eventId: e.currentTarget.dataset.event_id,
      eventName: e.currentTarget.dataset.name,
      eventLocation: e.currentTarget.dataset.venue_name,
      startTime: e.currentTarget.dataset.start_time,
      endTime: e.currentTarget.dataset.end_time,
      eventPrice:e.currentTarget.dataset.event_price
    });
  };

  const handleEventDateTime = (start, end) => {
    const startTime = format(parseISO(start), "dd. MMMM Y - H:mm", {
      locale: da,
    });

    let endTime = "";

    if (end !== null) {
      endTime = format(parseISO(end), "- H:mm");
    }
    return startTime + endTime;
  };

  //HOVER TEXT FOR København AND Aalborg
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Kommer snart!
    </Tooltip>
  );

  const eventListBottomFunc =
    state?.parkingListArray?.length === 0 ? (
      <div className="eventParkingListContainer">
        <div className="soldOutText">
          Parkeringspladser til {pressedEvent.eventName} er udsolgt
        </div>
        <div className="noParkeringContainer">
          <div className="text">
            <h3 className="paragraphHeading">Der er for meget trafik</h3>
            <p className="paragraph">
              Der er meget stor efterspørgsel på parkeringspladser til dette
              event. Vi har derfor desværre ikke flere tilgængelige
              parkeringspladser tilbage lige nu. Til de fleste events kan vi
              godt skaffe flere parkeringspladser, når vi ved en bilist ønsker
              pladsen. Skriv dig derfor meget gerne på vores venteliste
              nedenfor, så kontakter vi dig når vi har en ekstra parkeringsplads
              til eventet.
            </p>
            <HashLink smooth to="/event#waitinglist">
              <Button id="redirectionToWaitingListBtn">
                Kom på venteliste
              </Button>
            </HashLink>
          </div>
          <div className="images">
            <img src={car} alt="Blue car" />
            <img src={car} alt="Blue car" />
            <img src={car} alt="Blue car" />
            <img src={house} alt="Pink house" className="house" />
          </div>
        </div>
      </div>
    ) : (
      <div
        className="eventListBottomText spaceBelow"
        // onClick={() => scroll.scrollMore(700)}
      >
        {state?.parkingListArray?.length === 1 ? (
          <div className="flexContainer">
            <div className="arrowPlacer1">
              <div className="arrowContainer1">
                <div className="chevron"></div>
                <div className="chevron"></div>
                <div className="chevron"></div>
              </div>
              <div className="filler"></div>
            </div>
            <div className="eventArrowText">
              Der er {state?.parkingListArray?.length} ledig parkeringsplads til{" "}
              {pressedEvent.eventName}
            </div>
            <div className="arrowPlacer2">
              <div className="arrowContainer2">
                <div className="chevron2"></div>
                <div className="chevron2"></div>
                <div className="chevron2"></div>
              </div>
            </div>
          </div>
        ) : (
          <div className="pChecker">
            {/* Blank while parkinglistarray is loaded */}
            {state?.parkingListArray?.length === 0 ? (
              <div className="flexContainer">
                <div className="eventArrowText"></div>
              </div>
            ) : (
              <div className="flexContainer">
                <div className="arrowPlacer1">
                  <div className="arrowContainer1">
                    <div className="chevron"></div>
                    <div className="chevron"></div>
                    <div className="chevron"></div>
                  </div>
                  <div className="filler"></div>
                </div>
                <div className="eventArrowText">
                  Der er {state?.parkingListArray?.length} ledige
                  parkeringspladser til {pressedEvent.eventName}
                </div>
                <div className="arrowPlacer2">
                  <div className="arrowContainer2">
                    <div className="chevron2"></div>
                    <div className="chevron2"></div>
                    <div className="chevron2"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );

  //Creating the event list
  const events = state.eventList.map((event) => {
    let eventIcon = "";
    if (event.eventCategory === 0) {
      eventIcon = <img src={football} className="football" alt="" />;
    } else if (event.eventCategory === 1) {
      eventIcon = <img src={concertIcon} className="concertIcon" alt="" />;
    } else if (event.eventCategory === 2) {
      eventIcon = <img src={balloon} className="balloon" alt="" />;
    }
    let eventColor = "";
    if (event.eventCategory === 0) {
      eventColor = "colorFootball cardTop";
    } else if (event.eventCategory === 1) {
      eventColor = "colorMusic cardTop";
    } else if (event.eventCategory === 2) {
      eventColor = "colorOther cardTop";
    }

    //HOVER TEXT FOR event description
    const hoverEventText = (props) =>
      event.description.length >= 50 ? (
        <Tooltip id="button-tooltip" {...props}>
          {event.description}
        </Tooltip>
      ) : (
        <Tooltip></Tooltip>
      );

    return (
      <div className="card-container" id={event.eventId} key={event.eventId}>
        <Card
          className={
            pressedEvent.eventId === event.eventId.toString() ? "chosenOne" : ""
          }
          data-event_id={event.eventId}
          data-venue_id={event.venue.venueId}
          date-venue_name={event.venue.venueName}
          data-name={event.name}
          data-start_time={event.startTime}
          data-end_time={event.endTime}
          data-event_price={event.price}
          onClick={(e) => handleEventClick(e)}
        >
          <div className={eventColor}>
            <div className="iconContainer">{eventIcon}</div>
          </div>
          <Card.Body>
            <Card.Title>{event.name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {event.venue.name}
            </Card.Subtitle>
            <OverlayTrigger
              placement="bottom"
              delay={{show: 250, hide: 200}}
              overlay={hoverEventText}
            >
              <Card.Text>
                {event.description.length <= 50
                  ? event.description
                  : event.description.slice(0, 50) + "..."}
              </Card.Text>
            </OverlayTrigger>
          </Card.Body>
          <Card.Footer>
            <small className="text-muted">
              {handleEventDateTime(event.startTime, event.endTime)}
            </small>
          </Card.Footer>
        </Card>
      </div>
    );
  });

  const eventListBottom = pressedEvent.eventPressed ? (
    eventListBottomFunc
  ) : (
    <div className="eventListBottomText spaceBelow">
      {events.length >= 1 ? (
        <p>Tryk på et event for at se ledige parkeringspladser</p>
      ) : null}
    </div>
  );

  const parkingComponent = pressedEvent.eventPressed ? (
    <ParkingList
      parkingListArray={state.parkingListArray}
      imgArray={state.image.imgArray}
      isEvent={true}
      eventStartTime={pressedEvent.startTime}
      eventEndTime={pressedEvent.endTime}
      eventName={pressedEvent.eventName}
      eventLocation={pressedEvent.eventLocation}
      eventPrice={pressedEvent.eventPrice}
      eventId={pressedEvent.eventId}
    />
  ) : null;

  const swipeInfo = <p className="swipe spaceBelow"> &lt; Swipe højre / venstre &gt;</p>;

  return (
    <div className="eventList">
      <div className="eventContainer">
        <h2 className="sectionHeading white">
          Vælg det event du ønsker parkering til
        </h2>
        {/* TEMPORARY CITY FILTER */}
        <div className="cityButtons">
          <div className="cityBtn leftBtn pinkBtn">Aarhus</div>
          <OverlayTrigger
            placement="top"
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
          >
            <div className="cityBtn midBtn greyBtn">Aalborg</div>
          </OverlayTrigger>
          <OverlayTrigger
            placement="top"
            delay={{show: 250, hide: 400}}
            overlay={renderTooltip}
          >
            <div className="cityBtn rightBtn greyBtn">København</div>
          </OverlayTrigger>
        </div>

        <div className="slider-container">
          {events.length >= 1 ? (
            <Slider {...config}> {events} </Slider>
          ) : (
            <p className="loading">Indlæser...</p>
          )}
        </div>

        {swipeInfo}
        {eventListBottom}
      </div>

      {events.length >= 1 ? (
        <div className="eventParkingListContainer">{parkingComponent}</div>
      ) : null}
    </div>
  );
};

export default EventList;
