import {useState, useEffect} from "react";
import ParkingItem from "./ParkingItem";
import Pagination from "./Pagination";

import "../../Styles/Components/ParkingList/ParkingList.scss";

const ParkingList = (props) => {
  const {
    parkingListArray,
    imgArray,
    isEvent = false,
    eventStartTime,
    eventEndTime,
    eventName,
    eventPrice,
    eventId,
    isLoaded = false,
  } = props;

  // console.log("ParkingList - Consoling props  ", props);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    postsPerPage: 5,
    pageNumbers: [],
    minRange: 3,
    minPages: 9,
  });

  useEffect(() => {
    if (!isEvent && isLoaded) {
      // Pushing the content up
      var position = document.getElementById("parkinglist");
      position.scrollIntoView(true);
      window.scrollBy({
        top: -50,
        left: 0,
        behaviour: "smooth",
      });
    }
  }, [pagination.currentPage]);

  const currentPosts = parkingListArray?.slice(
    (pagination.currentPage - 1) * pagination.postsPerPage,
    pagination.currentPage * pagination.postsPerPage
  );

  const eventParkeringList = (
    <div className="eventParkingListComponent">
      {parkingListArray?.length > 1 ? (
        <h2 className="parkingListHeader">
          Ledige parkeringspladser til {eventName}
        </h2>
      ) : null}
      {parkingListArray?.map((spot) => {
        let spotID = spot.parkingSpotId;
        let img;
        imgArray.forEach((element) => {
          if (spotID === element.id) {
            if (element.img) {
              img = element.img;
            }
          }
        });
        return (
          <ParkingItem
            key={spot.parkingSpotId}
            spot={spot}
            img={img}
            isEvent={isEvent}
            eventStartTime={eventStartTime}
            eventEndTime={eventEndTime}
            eventName={eventName}
            price={eventPrice}
            eventId={eventId}
          />
        );
      })}
    </div>
  );

  const findParkingList = (
    <div className="parkingList">
      {currentPosts?.map((spot) => {
        let spotID = spot.parkingSpotId;
        let img;
        imgArray.forEach((element) => {
          if (spotID === element.id) {
            if (element.img) {
              img = element.img;
            }
          }
        });
        return (
          <ParkingItem
            key={spot.parkingSpotId}
            spot={spot}
            img={img}
            isEvent={isEvent}
            eventStartTime={eventStartTime}
            eventEndTime={eventEndTime}
            price={spot.price}
          />
        );
      })}
      <Pagination
        parkingListArray={parkingListArray}
        pagination={pagination}
        setPagination={setPagination}
      />
    </div>
  );

  const parkingItemContainer = isEvent ? eventParkeringList : findParkingList;

  return <div className="parkingListComponent">{parkingItemContainer}</div>;
};

export default ParkingList;
